<!-- 学员管理-绑定设备 -->
<template>
  <a-card>
    <div slot="title">待绑定学员信息</div>
    <!--新的学生信息-->
    <a-form-model ref="form" :model="form" style="width:1000px; margin: 0 auto; font-size: 16px;">
      <a-row>
        <a-col :span="6">
          <a-form-model-item style="display: flex" label="学员姓名："
            >{{ data.username || '--' }}
          </a-form-model-item></a-col
        >
        <a-col :span="6">
          <a-form-model-item style="display: flex" label="性别："
            >{{ { MALE: '男', FEMALE: '女' }[data.sex] || '--' }}
          </a-form-model-item></a-col
        >
        <a-col :span="6">
          <a-form-model-item style="display: flex; margin-bottom: 0" label="联系电话："
            >{{ data.cellphone || '--' }}
          </a-form-model-item></a-col
        >
        <a-col :span="6">
          <a-form-model-item style="display: flex; margin-bottom: 0" label="年龄："
            >{{ data.age || '--' }}
          </a-form-model-item></a-col
        >
      </a-row>

      <a-row style="margin-bottom: 22px">
        <a-form-model-item
          label="绑定设备号："
          prop="machineCode"
          :rules="{
            required: true,
            message: '请输入20位设备号',
            trigger: 'blur'
          }"
          class="custom-item"
          style="position: relative"
        >
          <a-input v-model.trim="form.machineCode" :maxLength="20" :disabled="isBind" />
          <span style="position: absolute; bottom: -60px; left: 0; color: red" v-if="!isBind">
            请输入需绑定的设备号，系统将为您查询可合并的账号
          </span>
        </a-form-model-item>
      </a-row>

      <a-form-model-item style="display: flex; justify-content: center;padding-top: 64px" v-if="!isBind">
        <a-button type="primary" shape="round" size="large" @click="handleNextStep()" style="width: 160px">
          下一步
        </a-button>
      </a-form-model-item>

      <!--旧的学员信息-->
      <div v-if="isBind">
        <a-row style="margin-bottom: 24px; font-weight: 600;">
          <a-col :span="24"> 根据您输入的绑定设备号，已查找到学员: </a-col></a-row
        >
        <a-row>
          <a-col :span="6">
            <a-form-model-item style="display: flex" label="学员姓名："
              >{{ oldStuData.username || '--' }}
            </a-form-model-item></a-col
          >
          <a-col :span="6">
            <a-form-model-item style="display: flex" label="性别：">
              {{ { MALE: '男', FEMALE: '女' }[oldStuData.sex] || '--' }}
            </a-form-model-item></a-col
          >
          <a-col :span="6">
            <a-form-model-item style="display: flex" label="联系电话："
              >{{ oldStuData.cellphone || '--' }}
            </a-form-model-item></a-col
          >
          <a-col :span="6">
            <a-form-model-item style="display: flex" label="年龄："
              >{{ oldStuData.age || '--' }}
            </a-form-model-item></a-col
          >
        </a-row>
        <a-row class="tips" style="margin-bottom: 64px">
          请确认待合并学员信息，系统将以较早的学员信息为准，自动合并以上学员数据，合并操作不可逆！请务必谨慎操作！
        </a-row>
        <a-row type="flex" justify="center">
          <a-button
            type="primary"
            shape="round"
            size="large"
            style="margin-right: 20px;width: 160px"
            @click="$router.go(-1)"
          >
            取消
          </a-button>
          <a-button shape="round" size="large" @click="handleSubmit()" style="width: 160px">
            确认合并
          </a-button>
        </a-row>
      </div>
    </a-form-model>
  </a-card>
</template>

<script>
import * as Api from '@/api/student'
export default {
  name: 'Bind',
  data() {
    return {
      form: {
        machineCode: ''
      },
      data: {}, // 新学生
      oldStuData: {}, // 旧学生
      isBind: false // true 可以绑定  false 不能绑
    }
  },
  created() {
    this.data = JSON.parse(this.$route.query.row)
  },
  methods: {
    // 下一步
    handleNextStep() {
      const { machineCode } = this.form
      const { id: studentId } = this.$route.params
      const params = { machineCode, studentId }
      Api.isBindDevices(params).then(res => {
        const { code, data, msg } = res
        if (code === 0) {
          this.isBind = data
          data && this.getOldStudentInfo()
        } else {
          this.$notification['error']({ message: '提示', description: `${msg}` })
        }
      })
    },
    // 查询旧学员
    getOldStudentInfo() {
      const { machineCode } = this.form
      Api.getStudentByMachineCode({ machineCode }).then(res => {
        const { code, data } = res
        if (code === 0) {
          this.oldStuData = data
        }
      })
    },
    handleSubmit() {
      this.$confirm({
        title: '您正在进行两位学员的数据合并',
        content: '该操作不可逆，是否确认合并？',
        onOk: () => {
          const { machineCode } = this.form
          const { id: studentId } = this.$route.params
          const params = { machineCode, studentId }
          Api.saveBindDevices(params).then(res => {
            if (res.code === 0) {
              this.$notification.success({
                message: '提示',
                description: '账号数据关联成功。'
              })
              this.$router.go(-1)
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.custom-item {
  display: flex;
  /deep/ .ant-form-item-control-wrapper {
    flex: 1;
  }
}

/deep/ .ant-form-item-label label {
  font-weight: 600;
}
/* 提示*/
.tips {
  margin-bottom: 60px;
  color: red;
}
</style>
